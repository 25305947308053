function isEmpty(obj) {
    return obj === null || obj === undefined;
}

function groupBy(xs, key) {
    return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
    }, {});
}

function formatNumber(num, type, decimalDigits = 2) {
    if (num === null || num === undefined) return '-';
    if (type === 'percent') return num.toLocaleString(undefined, { style: 'percent', minimumFractionDigits: decimalDigits });
    if (type === 'currency') return num.toLocaleString(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: decimalDigits });
    return num.toLocaleString();
}

function floor(number, decimals) {
    const factor = Math.pow(10, decimals);
    return Math.floor(number * factor) / factor;
}

function offset(value, factor) {
    return (1 + value) / (1 + factor) - 1
}

function calculatePayoff(hypoIndex, hypoIndex2, expenses, downside, upside, outcomePeriodDaysTotal, downsideRate, upsideRate, targetNetEquityOptionAllocation,
    outcomePeriodDaysRemaining, indexPer, indexPer2, fundPer, fixedIncomeContribution, forceNegativeZero) {

    const fullPeriodExpenses = 1 - Math.pow(1 - expenses / 365, outcomePeriodDaysTotal);
    const remainingPeriodExpenses = 1 - Math.pow(1 - expenses / 365, outcomePeriodDaysRemaining);
    const anticipatedFixedIncomeContributions = fixedIncomeContribution / Math.pow(1 - targetNetEquityOptionAllocation, outcomePeriodDaysRemaining / outcomePeriodDaysTotal);

    const value = { index: hypoIndex };
    if (!isEmpty(hypoIndex2))
        value.index2 = hypoIndex2;

    let secondary = 0;
    if (!isEmpty(hypoIndex2) && hypoIndex2 >= 0) {
        if (hypoIndex2 <= upsideRate / 2)
            secondary = hypoIndex2;
        else
            secondary = upsideRate / 2;
    }

    if (hypoIndex < 0 || forceNegativeZero) {
        if (downside === 'Buffer') {
            if (hypoIndex <= -downsideRate)
                value.fund = (1 - fullPeriodExpenses) * (1 + downsideRate + hypoIndex + secondary) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
            else
                value.fund = (1 - fullPeriodExpenses) * (1 + secondary) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
        else if (downside === 'Floor') {
            if (hypoIndex <= -downsideRate)
                value.fund = (1 - fullPeriodExpenses) * (1 - downsideRate) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
            else
                value.fund = (1 - fullPeriodExpenses) * (1 + hypoIndex) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
        else if (downside === 'Par') {
            value.fund = (1 - fullPeriodExpenses) * (1 + hypoIndex * downsideRate) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
    }
    else {
        if (upside === 'Par') {
            value.fund = (1 - fullPeriodExpenses) * (1 + hypoIndex * upsideRate) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
        else if (upside === 'Spread') {
            if (hypoIndex < upsideRate)
                value.fund = (1 - fullPeriodExpenses) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
            else
                value.fund = (1 - fullPeriodExpenses) * (1 - upsideRate + hypoIndex) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
        else if (upside === 'Cap') {
            if (hypoIndex <= upsideRate / 2)
                value.fund = (1 - fullPeriodExpenses) * (1 + hypoIndex      + secondary) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
            else
                value.fund = (1 - fullPeriodExpenses) * (1 + upsideRate / 2 + secondary) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
        else if (upside === 'Trigger') {
            value.fund = (1 - fullPeriodExpenses) * (1 + upsideRate) + (1 - remainingPeriodExpenses) * anticipatedFixedIncomeContributions - 1;
        }
    }

    // calculate fund2
    if (upside === 'Cap')
       value.fund2 = value.fund + (1 - fullPeriodExpenses) * upsideRate / 2;

    if (outcomePeriodDaysTotal !== outcomePeriodDaysRemaining) {
        value.index = offset(value.index, indexPer);
        value.index2 = offset(value.index2, indexPer2);
        value.fund = offset(value.fund, fundPer);
        if (!isEmpty(value.fund2)) value.fund2 = offset(value.fund2, fundPer);
    }

    return value;
}

function calculatePayoffProfile(expenses, downside, upside, outcomePeriodDaysTotal, downsideRate, upsideRate, targetNetEquityOptionAllocation,
    outcomePeriodDaysRemaining, indexPer, indexPer2, fundPer, fixedIncomeContribution, bounds, exclude = []) {

    const inputs = [expenses, downside, upside, outcomePeriodDaysTotal, downsideRate, upsideRate, targetNetEquityOptionAllocation,
        outcomePeriodDaysRemaining, indexPer, indexPer2, fundPer, fixedIncomeContribution];

    const payoffProfile = [];

    if (downside === 'Buffer') {
        payoffProfile.push(calculatePayoff(-bounds, null, ...inputs));
        payoffProfile.push(...exclusiveRange(-bounds, -downsideRate, exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(-downsideRate, null, ...inputs));
        payoffProfile.push(...exclusiveRange(-downsideRate, 0, exclude).map(b => calculatePayoff(b, null, ...inputs)));
    }
    else if (downside === 'Floor') {
        payoffProfile.push(calculatePayoff(-bounds, null,  ...inputs));
        payoffProfile.push(...exclusiveRange(-bounds, -downsideRate, exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(-downsideRate, null, ...inputs));
        payoffProfile.push(...exclusiveRange(-downsideRate, 0, exclude).map(b => calculatePayoff(b, null, ...inputs)));
    }
    else if (downside === 'Par') {
        payoffProfile.push(calculatePayoff(-bounds, null, ...inputs));
        payoffProfile.push(...exclusiveRange(-bounds, 0, exclude).map(b => calculatePayoff(b, null, ...inputs)));
    }


    if (upside === 'Par') {
        payoffProfile.push(calculatePayoff(0, null, ...inputs));
        payoffProfile.push(...exclusiveRange(0, Math.max(bounds, 1 + 2 * indexPer), exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(Math.max(bounds, 1 + 2 * indexPer), null, ...inputs));
    }
    else if (upside === 'Spread') {
        payoffProfile.push(...exclusiveRange(0, upsideRate).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(upsideRate, null, ...inputs));
        payoffProfile.push(...exclusiveRange(upsideRate, Math.max(bounds, 1 + 2 * indexPer), exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(Math.max(bounds, 1 + 2 * indexPer), null, ...inputs));
    }
    else if (upside === 'Cap') {
        payoffProfile.push(calculatePayoff(0, null, ...inputs));
        payoffProfile.push(...exclusiveRange(0, upsideRate / 2, exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(upsideRate / 2,  null, ...inputs));
        payoffProfile.push(...exclusiveRange(upsideRate / 2, Math.max(bounds, 1 + 2 * indexPer), exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(Math.max(bounds, 1 + 2 * indexPer), null, ...inputs));
    }
    else if (upside === 'Trigger') {
        payoffProfile.push(calculatePayoff(-0.0001, null, ...inputs, true));
        payoffProfile.push(calculatePayoff(0, null, ...inputs));
        payoffProfile.push(...exclusiveRange(0, Math.max(bounds, 1 + 2 * indexPer), exclude).map(b => calculatePayoff(b, null, ...inputs)));
        payoffProfile.push(calculatePayoff(Math.max(bounds, 1 + 2 * indexPer), null, ...inputs));
    }

    return payoffProfile;
}

function exclusiveRange(rangeMin, rangeMax, exclude = []) {
    const arr = [];
    const min = Math.floor(rangeMin * 100) + 1;
    const max = Math.ceil(rangeMax * 100) - 1;
    
    for (let i = min; i < max; ++i) {
        if (exclude.indexOf(i / 100) < 0)
            arr.push(i / 100);
    }
    
    return arr;
}

function getExpenseRatio(series)
{
    return series === 'capgroup' ? parseFloat(process.env.GATSBY_CAPGROUP_EXPENSE_RATIO) : parseFloat(process.env.GATSBY_EXPENSE_RATIO);
}

function getExpenseRatioGross(series)
{
    return series === 'capgroup' && process.env.GATSBY_CAPGROUP_EXPENSE_RATIO_GROSS ? parseFloat(process.env.GATSBY_CAPGROUP_EXPENSE_RATIO_GROSS) : null;
}

export {
    isEmpty,
    groupBy,
    formatNumber,
    floor,
    offset,
    calculatePayoff,
    calculatePayoffProfile,
    getExpenseRatio,
    getExpenseRatioGross
}